<template>
  <div class="split left">
    <div class="centered">
      <img alt="Vue logo" src="../assets/logo.png" />
    </div>
  </div>

  <div class="split right">
    <div class="centered">
        <router-view  v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// @ is an alias to /src
// import LoginForm from "@/components/LoginForm.vue";
// import SignupForm from "@/components/SignupForm.vue";
// import ForgotPasswordForm from "@/components/ForgotPasswordForm.vue";

export default {
  name: "InitPage",
  components: {
    // LoginForm,
    // SignupForm,
    // ForgotPasswordForm
  },
  data() {
    return {
      user: {
        username: 'Michael',
        password: '123456'
      }
    }
  },
  computed: {
    ...mapGetters(['forgotPassword', 'signup'])
  }
};
</script>
