import { createStore } from "vuex";
import accountModule from "./store/account/account.module";

//import user from "../Models/user";

export default createStore({
  modules: {
    accountModule
  }
});
