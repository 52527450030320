import { createRouter, createWebHistory } from "vue-router";
import InitPage from "../views/InitPage.vue";
import LoginForm from "../components/LoginForm.vue";
import SignupForm from "../components/SignupForm.vue";
import ForgotPasswordForm from "../components/ForgotPasswordForm.vue";

const routes = [
  {
    path: '/',
    name: 'init',
    redirect: '/login',
    component: InitPage,
    children: [
      {
        path: 'login',
        component: LoginForm,
        name: 'Login'
      },
      {
        path: 'signup',
        component: SignupForm,
        name: 'Signup'
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordForm,
        name: 'ForgotPassword'
      }
    ]
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
