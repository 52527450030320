<template>
    <div class="vue-template inner-block" >
        <form>
            <h3>Sign In</h3>

            <div class="form-group">
                <label>Email address</label>
                <input type="email" class="form-control form-control-lg" />
            </div>

            <div class="form-group">
                <label>Password</label>
                <input type="password" class="form-control form-control-lg" />
            </div>

            <button type="submit" class="btn btn-dark btn-lg btn-block">Sign In</button>

            <router-link class="btn btn-dark btn-lg btn-block" :to="{name: 'Signup'}">Sign up</router-link>
            
            <p class="forgot-password text-right mt-2 mb-4">
                <router-link :to="{name: 'ForgotPassword'}">Forgot password ?</router-link>
            </p>

            <div class="social-icons">
                <ul>
                    <li><a href="#"><i class="fa fa-google"></i></a></li>
                    <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                    <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                </ul>
            </div>

        </form>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: "LoginForm",
  props: {
    msg: {
      type: String,
      required: false,
      default: 'Hello, Katerina!'
    },
    user: {
      type: Object,
      required: true
      // validator: (prop) => prop.hasOwnProperty('username') && prop.hasOwnProperty('password')
    }
  },
  data() {
    return {
      localUsername: '',
      localPassword: ''
    }
  },
  computed: {
    ...mapGetters(['username', 'password']),
    usernameLength() {
      return this.username.length + this.password.length
    },
    username2: {
      get() {
        return this.username
      },
      set(value) {
        // validation
        this.setUsername(value)
      }
    }
  },
  watch: {
    // localUsername(newValue) {
    //   this.setUsername(newValue)
    // },
    localPassword(newValue) {
      this.setPassword(newValue)
    }
  },
  methods: {
    ...mapActions(['getToken']),
    ...mapMutations(['setUsername', 'setPassword', 'setForgotPassword', 'setSignup']),
    submitForm() {
      console.log('submitForm called');
    }
  },
  created() {
    //this.getToken();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
