// import { createStore } from "vuex";

import state from './account.state.js';
import getters from './account.getters.js';
import mutations from './account.mutations.js';
import actions from './account.actions.js';


const accountModule = {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
  };
  
  export default accountModule;
