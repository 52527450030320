import axios from 'axios';


const getToken = () => {
    // validate user details
    // call external api
    
    const query = {
      grant_type: 'client_credentials',
      client_id: 'theofila@liverpool.ac.uk',
      client_secret: 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjI1MmZjYjk3ZGY1YjZiNGY2ZDFhODg1ZjFlNjNkYzRhOWNkMjMwYzUiLCJ0eXAiOiJK',
      type: 'direct'
    }

    axios.post(`https://api.raivotech.com/api/token`, query)
      .then(response => {
        // JSON responses are automatically parsed.
        //this.posts = response.data
        //commit('setUsername', response.data.user.username)
        //commit('setPassword', response.data.user.password)
        console.log('resposne => ', response.data)
      })
      .catch(error => {
        //this.errors.push(e)
        console.log('error => ', error);
      })
  }


export default {
    getToken
}