
export default {
    // setUsername: (state, value) => {
    //   // validation
    //   state.username = value
    // },
    // setPassword: (state, value) => {
    //   // validation
    //   state.password = value
    // },
    // setUser: (state, value) => {
    //   new user()
    // }
}